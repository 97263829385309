import React from "react"

import * as style from "./Footer_style"

const Footer = () => {
  return (
    <style.Footer>
      <h1>Kopibeskyttelse © {new Date().getFullYear()} Ask Attilla Strande</h1>
    </style.Footer>
  )
}

export default Footer
