import React from "react"

import * as style from "./Header_style"

const Menu = () => {
  return (
    <style.Wrapper hidden={false}>
      <style.StyledLink to="/">aa</style.StyledLink>
    </style.Wrapper>
  )
}

export default Menu
